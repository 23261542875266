import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getPageUrl } from '../utils';

const getColorByCategory = category =>
	category === 'wiedza' ? '#9013FE' : '#FF5F4E';

const StyledTileImage = styled('img')`
	display: block;
	margin: 0 auto;
	object-fit: cover;
	height: 100%;
	width: 100%;
`;

const StyledTileMeta = styled('div')`
	margin-top: -1em;
`;

const StyledTileLabel = styled('span')`
	display: inline-block;
	background-color: #fff;
	border: 2px solid ${props => getColorByCategory(props.category)};
	text-transform: uppercase;
	font-size: 0.8em;
	padding: 0.1em 0.7em;
	margin-top: -0.5em;
	position: relative;
	z-index: 4;
`;

const StyledTileTitle = styled('h3')`
	margin: 0.5em 0;
	margin-bottom: 0.3em;
`;

const StyledTileSubtitle = styled('span')`
	font-size: 0.9em;
	font-weight: 600;
`;

const StyledImageContainer = styled('div')`
	position: relative;
	height: ${props => (props.size === 'absolute' ? '28vw' : '380px')};
	display: flex;
	align-items: center;

	@media (max-width: 840px) {
		height: ${props => (props.size === 'absolute' ? '42vw' : '250px')};
	}

	@media (max-width: 480px) {
		height: ${props => (props.size === 'absolute' ? '91vw' : '330px')};
	}
`;

const StyledHoverText = styled('div')`
	display: none;
	color: #000;
	position: absolute;
	z-index: 5;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 2.6em;
	text-transform: uppercase;
	font-family: 'Work Sans', sans-serif;
`;

const StyledImageFilter = styled('div')`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	background-color: ${props =>
		props.disable ? 'transparent' : 'rgba(255, 95, 78, 0.6)'};
	transition: all 0.2s;
`;

const StyledTileLink = styled(Link)`
	color: inherit;
	text-decoration: none;
	flex-grow: 0;
	width: ${props => (props.size === 'absolute' ? '28vw' : '100%')};
	margin-bottom: 60px;
	position: relative;

	&:hover {
		${StyledImageFilter} {
			background-color: ${props => getColorByCategory(props.category)};
			border: 2px solid #000;
		}
		${StyledHoverText} {
			display: block;
		}
	}

	@media (max-width: 840px) {
		width: ${props => (props.size === 'absolute' ? '42vw' : '100%')};
	}

	@media (max-width: 480px) {
		width: ${props => (props.size === 'absolute' ? '91vw' : '100%')};
	}
`;

const Tile = ({
	title,
	category,
	subtitle,
	slug,
	thumbnail,
	image,
	size = 'absolute'
}) => (
	<StyledTileLink
		to={getPageUrl(category, slug)}
		category={category}
		size={size}
	>
		{thumbnail && (
			<StyledImageContainer size={size}>
				<StyledHoverText>Zobacz</StyledHoverText>
				<StyledImageFilter disable={true} />
				<StyledTileImage
					alt=""
					src={thumbnail.childImageSharp.sizes.src}
					srcSet={thumbnail.childImageSharp.sizes.srcSet}
				/>
			</StyledImageContainer>
		)}
		{!thumbnail && image && (
			<StyledImageContainer size={size}>
				<StyledHoverText>Zobacz</StyledHoverText>
				<StyledImageFilter />
				<StyledTileImage
					alt=""
					src={image.childImageSharp.sizes.src}
					srcSet={image.childImageSharp.sizes.srcSet}
				/>
			</StyledImageContainer>
		)}
		<StyledTileMeta>
			<StyledTileLabel category={category}>{category}</StyledTileLabel>
			<StyledTileTitle>{title}</StyledTileTitle>
			<StyledTileSubtitle>{subtitle}</StyledTileSubtitle>
		</StyledTileMeta>
	</StyledTileLink>
);

export default Tile;

import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Footer from '../components/Footer';
import InnerContainer from '../components/InnerContainer';
import HomeScreenContent from '../components/HomeScreenContent';
import Layout from '../components/layout';

import { knowledgePrimary, activityPrimary } from '../contants/colors';
import TopBar from '../components/TopBar';

const StyledContainer = styled('div')``;

const StyledBanner = styled('div')`
	display: flex;
	flex-grow: 1;
	position: relative;
	overflow: hidden;
`;

const StyledHeader = styled('div')`
	height: 86vh;
	display: flex;
	flex-direction: column;
	align-content: stretch;
`;

const StyledBannerContent = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	height: 100%;
`;

const StyledBannerText = styled('p')`
	font-size: 2.3em;
	font-family: 'Work Sans', sans-serif;
	font-weight: 800;
	line-height: 1.2em;
	text-align: center;
	position: relative;
	z-index: 9;
	margin: 1em 0;

	@media (max-width: 1200px) {
		font-size: 2em;
	}

	@media (max-width: 1000px) {
		font-size: 1.8em;
	}

	@media (max-width: 480px) {
		font-size: 1.6em;
	}
`;

const commonFigureCss = css`
	display: block;
	width: 300px;
	height: 300px;
	position: absolute;
`;

const StyledTriangle = styled('svg')`
	${commonFigureCss};
	fill: ${activityPrimary};
	transform: rotate(90deg);
	bottom: -50px;
	z-index: 2;
	left: -80px;

	@media (max-width: 900px) {
		display: none;
	}
`;

const StyledTriangleRight = styled('svg')`
	${commonFigureCss};
	fill: ${knowledgePrimary};
	transform: rotate(80deg);
	bottom: 120px;
	z-index: 2;
	right: -140px;

	@media (max-width: 900px) {
		display: none;
	}
`;

const StyledCircle = styled('svg')`
	${commonFigureCss};
	fill: #000000;
	z-index: 2;
	bottom: -150px;
	right: 300px;

	@media (max-height: 680px) {
		bottom: -230px;
		right: 20px;
	}
	@media (max-width: 900px) {
		bottom: -230px;
		right: -100px;
	}
`;

const StyledLine = styled('svg')`
	display: block;
	position: absolute;
	height: 160px;
	top: -10px;
	stroke-width: 5px;
	left: calc(50% - 50px);
	z-index: 1;

	@media (max-width: 680px) {
		left: calc(50% - 150px);
		top: -40px;
	}
`;

const IndexPage = ({ data }) => {
	const knowledgeTiles = data.allMarkdownRemark.group.find(
		group => group.fieldValue === 'wiedza'
	).edges;
	const activityTiles = data.allMarkdownRemark.group.find(
		group => group.fieldValue === 'działanie'
	).edges;
	return (
		<Layout>
			<Helmet>
				<title>
					do dizajnu - baza wiedzy i scenariuszy na działania
					dotyczące dizajnu
				</title>
				<html lang="pl" />
			</Helmet>
			<StyledContainer>
				<StyledHeader>
					<TopBar />
					<StyledLine viewBox="0 0 135 80">
						<line
							x1="0"
							y1="0"
							x2="130"
							y2="75"
							stroke={activityPrimary}
						/>
					</StyledLine>
					<StyledBanner>
						<StyledTriangle
							width="16"
							height="10"
							viewBox="0 0 20 20"
						>
							<polygon points="0,16 10,0 20,16" />
						</StyledTriangle>
						<StyledTriangleRight
							width="16"
							height="10"
							viewBox="0 0 20 20"
						>
							<polygon points="0,16 10,0 20,16" />
						</StyledTriangleRight>
						<StyledCircle viewBox="0 0 50 50">
							<circle cx="25" cy="25" r="25" />
						</StyledCircle>
						<InnerContainer>
							<StyledBannerContent>
								<div>
									<StyledBannerText>
										do dizajnu to baza wiedzy i scenariuszy
										na działania <br />
										dotyczące dizajnu, stworzona z myślą o
										dzieciach i ich bliskich.
									</StyledBannerText>
									<StyledBannerText bigger>
										Dizajn to nasza codzienność. <br />{' '}
										Najwyższy czas dobrze ją poznać!
									</StyledBannerText>
								</div>
							</StyledBannerContent>
						</InnerContainer>
					</StyledBanner>
				</StyledHeader>
				<HomeScreenContent
					knowledgeTiles={knowledgeTiles}
					activityTiles={activityTiles}
				/>
				<Footer />
			</StyledContainer>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	{
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
			group(field: frontmatter___category) {
				fieldValue
				totalCount
				edges {
					node {
						id
						frontmatter {
							title
							subtitle
							date
							category
							slug
							thumbnail {
								childImageSharp {
									sizes(maxWidth: 800) {
										src
										srcSet
									}
								}
							}
							image {
								childImageSharp {
									sizes(maxWidth: 800) {
										src
										srcSet
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

import * as React from 'react';
import Tile from '../components/Tile';
import styled from 'styled-components';
import InnerContainer from './InnerContainer';
import IconMozg from '../images/mozg.svg';
import IconKredka from '../images/kredka.svg';

const StyledContentContainer = styled('div')`
	position: relative;
	z-index: 3;
	border-top: 3px solid #000;
`;

const StyledCategorySwitcher = styled('ul')`
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;

	& > li:first-child {
		border-right: 3px solid black;
	}
`;

const StyledCategoryTab = styled('li')`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	flex-grow: 1;
	color: #fff;
	font-family: 'Work Sans', sans-serif;
	font-weight: 800;
	font-size: 2.5rem;
	padding: 0.8em;
	text-align: center;
	background: ${props =>
		props['data-name'] === 'knowledge' ? '#9013FE' : '#FF5F4E'};
	border-bottom: ${props =>
		props.active ? '3px solid transparent' : '3px solid #000'};

	&:hover {
		cursor: pointer;

		img {
			transform: scale(1.1);
			transition: transform 0.1s;
		}
	}

	@media (max-width: 900px) {
		font-size: 2rem;
	}

	@media (max-width: 480px) {
		flex-direction: column;
		width: 50%;
	}
`;

const StyledSwitcherContentActivity = styled('div')`
	background-color: #ff5f4e;
	padding: 5em;
	padding-bottom: 200px;
	color: #fff;
	font-size: 1.2em;
	@media (max-width: 900px) {
		padding: 3em;
	}
`;

const StyledSwitcherContentKnowledge = styled('div')`
	background-color: #9013fe;
	padding: 5em;
	padding-bottom: 200px;
	color: #fff;
	font-size: 1.2em;
	@media (max-width: 900px) {
		padding: 3em;
	}
`;

const StyledSwitcherP = styled('p')`
	margin: 0 auto;
	max-width: 800px;
	line-height: 1.4em;
`;

const StyledSwitcherPActivity = styled('p')`
	margin: 0 auto;
	max-width: 800px;
	line-height: 1.4em;
`;

const StyledArrow = styled('svg')`
	fill: #fff;
	width: 100%;
	height: 130px;
	transform: translateY(-100%);
	display: block;

	@media (max-width: 900px) {
		height: 40px;
	}
`;

const StyledTileContainer = styled('div')`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	text-align: center;
	& > div {
		width: 30%;
	}
`;

const StyledTileOuterWrapper = styled('div')`
	margin-top: -4em;

	@media (max-width: 900px) {
		margin-top: -1em;
	}
`;

const StyledTabIcon = styled('img')`
	display: block;
	margin: 0;
	margin-right: 0.8em;
	height: 70px;

	@media (max-width: 480px) {
		margin-right: 0;
	}
`;

class HomeScreenContent extends React.Component {
	state = {
		activeTab: 'knowledge'
	};

	componentDidMount() {
		this.setState({
			activeTab:
				(typeof localStorage !== 'undefined' &&
					localStorage.getItem('activeTab')) ||
				'knowledge'
		});
	}

	handleTabClick = activeTab => {
		this.setState({
			activeTab
		});
		typeof localStorage !== 'undefined' &&
			localStorage.setItem('activeTab', activeTab);
		const ref =
			activeTab === 'knowledge'
				? this.state.knowledgeTabRef
				: this.state.activityTabRef;
		if (!ref || !ref.scrollIntoView) {
			return;
		}
		ref.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	handleTabActivityClick = () => {
		this.handleTabClick('activity');
	};

	handleTabKnowledgeClick = () => {
		this.handleTabClick('knowledge');
	};

	registerKnowledgeTabRef = ref => this.setState({ knowledgeTabRef: ref });

	registerActivityTabRef = ref => this.setState({ activityTabRef: ref });

	render() {
		const { activeTab } = this.state;
		const { knowledgeTiles, activityTiles } = this.props;
		return (
			<StyledContentContainer>
				<StyledCategorySwitcher>
					<StyledCategoryTab
						active={activeTab === 'knowledge'}
						data-name="knowledge"
						onClick={this.handleTabKnowledgeClick}
						ref={this.registerKnowledgeTabRef}
					>
						<StyledTabIcon src={IconMozg} alt="wiedza" />
						<div>Wiedza</div>
					</StyledCategoryTab>
					<StyledCategoryTab
						active={activeTab === 'activity'}
						data-name="activity"
						onClick={this.handleTabActivityClick}
						ref={this.registerActivityTabRef}
					>
						<StyledTabIcon src={IconKredka} alt="działanie" />
						<span>Działanie</span>
					</StyledCategoryTab>
				</StyledCategorySwitcher>
				{activeTab === 'knowledge' && (
					<div id="tab-knowledge-content">
						<StyledSwitcherContentKnowledge>
							<StyledSwitcherP>
								Dowiedz się czym jest dizajn, poznaj
								najważniejsze pojęcia z nim związane.
								Przechodząc przez kolejne pomieszczenia domu, a
								także zaglądając do garażu i ogrodu, poznasz
								dziedziny dizajnu i przykłady rzeczy
								zaprojektowanych, które znasz z codziennego
								otoczenia.
							</StyledSwitcherP>
						</StyledSwitcherContentKnowledge>
						<div>
							<StyledArrow
								width="16"
								height="10"
								preserveAspectRatio="none"
								viewBox="0 0 16 10"
							>
								<polygon points="0,10 16,10 8,0" />
							</StyledArrow>
							<InnerContainer>
								<StyledTileContainer>
									{knowledgeTiles.map((tile, index) => (
										<Tile
											category={
												tile.node.frontmatter.category
											}
											description={tile.node.excerpt}
											key={index}
											title={tile.node.frontmatter.title}
											slug={tile.node.frontmatter.slug}
											thumbnail={
												tile.node.frontmatter.thumbnail
											}
											image={tile.node.frontmatter.image}
										/>
									))}
								</StyledTileContainer>
							</InnerContainer>
						</div>
					</div>
				)}
				{activeTab === 'activity' && (
					<div id="tab-activity-content">
						<StyledSwitcherContentActivity>
							<StyledSwitcherPActivity>
								Sprawdź czym jest dizajn w działaniu. Skorzystaj
								ze scenariuszy warsztatów i zadań, dzięki którym
								zaprojektujesz różne obiekty, zadasz sobie
								najważniejsze pytania dotyczące naszego
								otoczenia i przyjrzysz się dokładniej
								przedmiotom wokół ciebie.
							</StyledSwitcherPActivity>
						</StyledSwitcherContentActivity>
						<StyledTileOuterWrapper>
							<InnerContainer>
								<StyledTileContainer>
									{activityTiles.map((tile, index) => (
										<Tile
											category={
												tile.node.frontmatter.category
											}
											key={index}
											subtitle={
												tile.node.frontmatter.subtitle
											}
											title={tile.node.frontmatter.title}
											slug={tile.node.frontmatter.slug}
											thumbnail={
												tile.node.frontmatter.thumbnail
											}
											image={tile.node.frontmatter.image}
										/>
									))}
								</StyledTileContainer>
							</InnerContainer>
						</StyledTileOuterWrapper>
					</div>
				)}
			</StyledContentContainer>
		);
	}
}

export default HomeScreenContent;
